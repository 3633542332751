import React, { useEffect } from "react"
import "../assets/styles/components/hasAccountManager.scss"
import { useInputChange } from "../custom-hook/useForm"
import RadioButton from "./accountRadioInput"

const HasAccountManager = (props: { [keys: string]: any }) => {
  const { setIsDisabled, formInputRef } = props
  const [input, handleInputChange] = useInputChange()

  const isCompleted = !input.hasAccountManager

  useEffect(() => {
    setIsDisabled(isCompleted)
    if (Object.keys(input).length != 0) Object.assign(formInputRef.current, input)
  }, [isCompleted, input])

  return (
    <div className="has-account-manager">
      <h3 className="h3-heading">Does anyone else manage this account?</h3>
      <span className="desc-1">
        Let us know if anyone else will log in to manage reservations or payments for this Host account
        <span>
          <button className="learn-btn">Learn more</button>
        </span>
      </span>
      <div className="has-account-manager-form">
        <RadioButton
          name="hasAccountManager"
          value="yes"
          checked={input.hasAccountManager === "yes"}
          onChange={handleInputChange}
        >
          Yes
        </RadioButton>
        <RadioButton
          name="hasAccountManager"
          value="No, it’s just me"
          checked={input.hasAccountManager === "No, it’s just me"}
          onChange={handleInputChange}
        >
          No, it’s just me
        </RadioButton>
      </div>
    </div>
  )
}

export default HasAccountManager
